<template>
  <page-header-wrapper>

    <!--头部-->
    <a-row :gutter="24">
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">总真实注册数</span>
              </div>
              <div>
                <span class="total-left">{{ home.totalUserRegisterCount }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop7" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">当月注册数</span>
              </div>
              <div>
                <span class="total-left">{{ home.monthUserRegisterCount }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop7" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">当日注册数 / 昨日注册数</span>
              </div>
              <div>
                <span class="total-left">{{ home.todayUserRegisterCount }} / {{ home.yesterdayUserRegisterCount }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop8" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">当日日活数 / 昨日日活数</span>
              </div>
              <div>
                <span class="total-left">{{ home.todayDailyActiveUsersCount }} / {{ home.yesterdayDailyActiveUsersCount }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop10" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <!--top chart1 总销售额-->
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">组局已验券的总销售额</span>
                <span class="chart-card-action">
                  <a-tooltip title="组局已验券的总销售额">
                    <a-icon type="info-circle-o"/>
                  </a-tooltip>
                </span>
              </div>
              <div>
                <span class="total-left">{{ home.sales }}</span>
                <span class="total-right">(元)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop1" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">平台总服务费</span>
                <span class="chart-card-action">
                  <a-tooltip title="平台总服务费">
                    <a-icon type="info-circle-o"/>
                  </a-tooltip>
                </span>
              </div>
              <div>
                <span class="total-left">{{ home.servicePrice }}</span>
                <span class="total-right">(元)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop16" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">组局已验券的总销售额(每日)</span>
                <span class="chart-card-action">
                  <a-tooltip title="组局已验券的总销售额">
                    <a-icon type="info-circle-o"/>
                  </a-tooltip>
                </span>
              </div>
              <div>
                <span class="total-left">{{ home.salesDay }}</span>
                <span class="total-right">(元)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop17" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">平台总服务费（每日）</span>
                <span class="chart-card-action">
                  <a-tooltip title="平台总服务费">
                    <a-icon type="info-circle-o"/>
                  </a-tooltip>
                </span>
              </div>
              <div>
                <span class="total-left">{{ home.servicePriceDay }}</span>
                <span class="total-right">(元)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop18" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>

      <!--top chart3 总评论数-->
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">总动态数</span>
              </div>
              <div>
                <span class="total-left">{{ home.trendstype }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop7" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">总剧评数数</span>
              </div>
              <div>
                <span class="total-left">{{ home.dramaticcriticismtype }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop8" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">当月剧评数</span>
              </div>
              <div>
                <span class="total-left">{{ home.monthScriptEvaluateCount }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop7" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }" v-if="!home.showControlMoreData">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">总展评数</span>
              </div>
              <div>
                <span class="total-left">{{ home.exhibitionreviewtype }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop9" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">总店评数</span>
              </div>
              <div>
                <span class="total-left">{{ home.shoptype }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop10" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>

      <!--top chart3 总评论数-->
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">总动态数(每日)</span>
              </div>
              <div>
                <span class="total-left">{{ home.trendstypeDay }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop11" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">总剧评数数(每日)</span>
              </div>
              <div>
                <span class="total-left">{{ home.dramaticcriticismtypeDay }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop12" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }" v-if="!home.showControlMoreData">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">总展评数(每日)</span>
              </div>
              <div>
                <span class="total-left">{{ home.exhibitionreviewtypeDay }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop13" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">总店评数(每日)</span>
              </div>
              <div>
                <span class="total-left">{{ home.shoptypeDay }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop14" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>

      <!--top chart1 总销售额-->
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }" v-if="!home.showControlMoreData">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">活动总销售额</span>
                <span class="chart-card-action">
                  <a-tooltip title="已支付订单总金额,未剔除售后订单">
                    <a-icon type="info-circle-o"/>
                  </a-tooltip>
                </span>
              </div>
              <div>
                <span class="total-left">{{ home.activitySales }}</span>
                <span class="total-right">(元)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop4" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <!--top chart2 总订单量-->
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }" v-if="!home.showControlMoreData">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">活动总订单量</span>
                <span class="chart-card-action">
                  <a-tooltip title="已支付订单总订单量,未剔除售后订单">
                    <a-icon type="info-circle-o"/>
                  </a-tooltip>
                </span>
              </div>
              <div>
                <span class="total-left">{{ home.activityOrderCount }}</span>
                <span class="total-right">(单)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop5" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <!--top chart3 总评论数-->
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }" v-if="!home.showControlMoreData">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">用户完成活动数</span>
              </div>
              <div>
                <span class="total-left">{{ home.activityUserCount }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop6" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>

      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }" v-if="!home.showControlMoreData">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">活动总数</span>
              </div>
              <div>
                <span class="total-left">{{ home.activityCount }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>

      <!--top chart3 总评论数-->
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">总店铺量</span>
              </div>
              <div>
                <span class="total-left">{{ home.shopCount }}</span>
                <span class="total-right">(个)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
      <!--top chart2 总订单量-->
      <a-col :sm="24" :md="12" :xl="6" :style="{ marginBottom: '24px' }">
        <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="false">
          <div>
            <div class="chart-card-header">
              <div class="meta">
                <span class="chart-card-title">组局总订单量</span>
                <span class="chart-card-action">
                  <a-tooltip title="已支付订单总订单量,未剔除售后订单">
                    <a-icon type="info-circle-o"/>
                  </a-tooltip>
                </span>
              </div>
              <div>
                <span class="total-left">{{ home.orderCount }}</span>
                <span class="total-right">(单)</span>
              </div>
            </div>
            <div class="chart-card-content">
              <div id="chartTop2" :style="{width: '100%', height: '50px'}"></div>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <!--    <a-row :gutter="24">
          <a-col :sm="24" :md="12" :xl="12" :style="{ marginBottom: '24px' }">
            <a-card
              class="project-list"
              style="margin-bottom: 24px;"
              :bordered="false"
              title="订单类型统计">
              <div class="user-data-div">
                <a-card :body-style="{ padding: '10px' }" style="margin: 10px;padding: -10px;">
                  <div class="chart-card-header">
                    <div id="c1" :style="{width: '100%', height: '250px'}"></div>
                  </div>
                </a-card>
              </div>
            </a-card>
          </a-col>

          <a-col :sm="24" :md="12" :xl="12" :style="{ marginBottom: '24px' }">
            <a-card
              class="project-list"
              style="margin-bottom: 24px;"
              :bordered="false"
              title="订单状态统计">
              <div class="user-data-div">
                <a-card :body-style="{ padding: '10px' }" style="margin: 10px;padding: -10px;">
                  <div class="chart-card-header">
                    <div id="mountNode" :style="{width: '100%', height: '250px'}"></div>
                  </div>
                </a-card>
              </div>

            </a-card>
          </a-col>
        </a-row>-->
  </page-header-wrapper>

</template>

<script>
import { timeFix } from '@/utils/util'
import { mapGetters } from 'vuex'
import { PageHeaderWrapper } from '@/components/ProLayout'
import { getUserProfile } from '@/api/system/user'
import { getHomeProfile, getOrderStatusDataProfile } from '@/api/system/home'

export default {
  name: 'Index',
  components: {
    PageHeaderWrapper
  },
  data() {
    return {
      noTitleKey: 'RuoYi',
      timeFix: timeFix(),
      // 用户信息
      user: {
        dept: {
          deptName: ''
        }
      },
      roleGroup: {},
      postGroup: {},
      home: {},
      orderStatusData: []
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'nickname'
    ])
  },
  created() {
    this.getUser()
    this.getHome()
    this.getOrderStatusData()
  },
  mounted() {
  },

  methods: {
    // 获取用户信息
    getUser() {
      getUserProfile().then(response => {
        this.user = response.data
        this.roleGroup = response.roleGroup
        this.postGroup = response.postGroup
      })
    },
    // 获取首页信息
    getHome() {
      getHomeProfile().then(response => {
        this.home = response.data
      })
    },
    // 获取订单状态
    getOrderStatusData() {
      getOrderStatusDataProfile().then(response => {
        this.orderStatusData = response.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.circle-cust {
  position: relative;
  top: 28px;
  left: -100%;
}

.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}

/* 首页访问量统计 */
.head-info {
  position: relative;
  text-align: left;
  padding: 0 32px 0 0;
  min-width: 125px;

  &.center {
    text-align: center;
    padding: 0 32px;
  }

  span {
    color: rgba(0, 0, 0, .45);
    display: inline-block;
    font-size: .95rem;
    line-height: 42px;
    margin-bottom: 4px;
  }

  p {
    line-height: 42px;
    margin: 0;

    a {
      font-weight: 600;
      font-size: 1rem;
    }
  }
}

.chart-card-header {
  position: relative;
  overflow: hidden;
  width: 100%;

  .meta {
    position: relative;
    overflow: hidden;
    width: 100%;
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px;
  }
}

.total-left {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  color: #000;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 38px;
  height: 38px;
  float: left;
}

.total-right {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  color: #000;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 38px;
  height: 38px;
  float: left;
  margin-left: 20px;
}

.chart-card-action {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
