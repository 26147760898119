import request from '@/utils/request'
import { parseStrEmpty } from '@/utils/ruoyi'

// 查询用户列表
export function getHomeProfile (query) {
  return request({
    url: '/sys/home/list',
    method: 'get',
    params: query
  })
}
// 查询订单状态
export function getOrderStatusDataProfile (query) {
  return request({
    url: '/sys/home/orderStatusData',
    method: 'get',
    params: query
  })
}
